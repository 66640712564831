import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { t } from '../../../locale';

class LineItemsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { guests: [] };
  }

  async componentDidMount() {
    const filter = { 'customAttributes.orderId': this.props.id };
    const { data } = await this.props.getCustomers({
      variables: {
        eventId: window.eventId,
        limit: 1000,
        offset: 0,
        searchTerm: '',
        filter: JSON.stringify(filter),
        sort: 'internalId:ASC',
      },
      onCompleted: (result) => {
        if (!result) return;
        this.setState({ guests: result?.getCustomers?.participants || [] });
      },
    });
    this.setState({ guests: data.getCustomers.participants });
  }

  render() {
    const attendeesColumn = [
      { text: t('customers.table.forename'), dataField: 'forename' },
      { text: t('customers.table.name'), dataField: 'name' },
      { text: t('customers.table.email'), dataField: 'email' },
      { text: t('customers.table.ticketCategory'), dataField: 'ticketCategory.name' },
    ];

    return (
      <div style={{ marginBottom: 10, padding: '0px 30px' }}>
        <h4>Tickets</h4>
        <BootstrapTable
          keyField="id"
          remote
          data={this.state.guests}
          columns={attendeesColumn}
          striped
          bordered={false}
          noDataIndication={() => <p style={{ textAlign: 'center' }}>{t('general.noData')}</p>}
        />
      </div>
    );
  }
}

export default LineItemsTable;
