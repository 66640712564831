import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  ControlLabel, Form, FormControl, FormGroup, Glyphicon, Label, PageHeader,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import { useDebounce, useSearchParam, useSessionStorage } from 'react-use';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';

import { useLazyQuery, useQuery } from '@apollo/client';
import { getCustomersGql, getOrdersGql/* , getTicketCategoriesGql */ } from '../gql';
import toastHelper from '../../../lib/toastHelper';
import AdvancedBootstapTable, { paginationTotalRenderer, sizePerPageRenderer } from '../../../components/AdvancedBootstapTable';
import LineItemsTable from '../components/LineItemsTable';

const euroFormatter = new Intl.NumberFormat('de-de', { style: 'currency', currency: 'EUR' });

function OrderPage() {
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useSessionStorage(`customerPaymentSearch_${window.eventId}`, '');
  const [/* tableFilter */, setTableFilter] = useState([]);
  const filterQueryString = useSearchParam('filter');
  const { t } = useTranslation('orders');

  /* const { data: categoriesResult } = useQuery(getTicketCategoriesGql, {
    variables: { eventId: window.eventId },
  }); */

  /* const filterToSend = useMemo(() => {
    const payerCategoryIds = payerCategories.map(({ id }) => id);
    let filterToSend = { $and: [] };
    if (Object.keys(filter).length) filterToSend.$and.push(filter);
    if (payerCategoryIds && payerCategoryIds.length) filterToSend.$and.push({ ticketCategoryId: { $in: payerCategoryIds } });

    if (tableFilter && tableFilter.length) {
      filterToSend = { $and: [...tableFilter, ...filterToSend.$and] };
    }
    if (!filterToSend.$and.length) return '';
    return encodeURIComponent(JSON.stringify(filterToSend));
  }, [filter, tableFilter, payerCategories]); */

  const { data: { getOrders: { orders, meta } = { orders: [], meta: {} } } = {} } = useQuery(getOrdersGql, {
    fetchPolicy: 'no-cache',
    variables: {
      eventId: window.eventId,
      limit: sizePerPage,
      offset: (page - 1) * sizePerPage,
      searchTerm: encodeURIComponent(debouncedSearchTerm?.trim()),
      /* filter: filterToSend,
      sort: '', */
    },
    onError: (err) => {
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
  });

  const [getCustomers] = useLazyQuery(getCustomersGql, {
    fetchPolicy: 'no-cache',
    variables: {
      eventId: window.eventId,
    },
    onError: (err) => {
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
  });

  useDebounce(
    () => { setDebouncedSearchTerm(searchTerm); setPage(1); },
    400,
    [searchTerm],
  );

  useEffect(() => {
    if (filterQueryString) {
      setSearchTerm('');
      return;
    }
    setSearchTerm(debouncedSearchTerm);
  }, []);

  const columns = [
    {
      text: 'Bestellnr.',
      dataField: 'orderNumber',
    },
    {
      text: t('table.invoiceNumber'),
      dataField: 'invoiceNumber',
    },
    {
      text: t('table.invoiceName'),
      dataField: 'invoiceAddress',
      formatter: (cellContent) => `${cellContent.forename} ${cellContent.name}`,
    },
    {
      text: t('table.orderDate'),
      dataField: 'submitDate',
      formatter: (cellContent) => cellContent && moment(cellContent).format('lll'),
    },
    {
      text: t('table.status'),
      dataField: 'status',
      formatter: (status) => {
        if (status === 'open') return (<Label bsStyle="danger">{t('status.open')}</Label>);
        if (status === 'complete') return (<Label bsStyle="success">{t('status.complete')}</Label>);
        return (<Label bsStyle="default">Unbekannt</Label>);
      },
    },
    {
      text: t('table.paymentStatus'),
      dataField: 'paymentStatus',
      formatter: (status) => {
        if (status === 'open') return (<Label bsStyle="danger">{t('paymentStatus.open')}</Label>);
        if (status === 'processing') return (<Label bsStyle="warning">{t('paymentStatus.processing')}</Label>);
        if (status === 'succeeded') return (<Label bsStyle="success">{t('paymentStatus.succeeded')}</Label>);
        return (<Label bsStyle="default">Unbekannt</Label>);
      },
    },
    {
      text: t('table.total'),
      dataField: 'total',
      formatter: (cell) => (cell !== undefined && cell !== null ? euroFormatter.format(cell) : ''),
    },
    {
      text: 'Aktion',
      isDummyField: true,
      headerStyle: () => ({ width: '70px' }),
      formatter: (cellContent, row) => (
        <ButtonGroup>
          <Button
            bsStyle="info"
            title={t('customers.showTicket')}
            href={`/customers/order/${window.eventId}/${row?.id}`}
          >
            <Glyphicon glyph="eye-open" />
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  const expandRow = {
    renderer: (customer) => <LineItemsTable {...customer} getCustomers={getCustomers} />,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnRenderer: ({ expanded }) => (
      <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={expanded ? faCaretDown : faCaretRight} />
    ),
    expandHeaderColumnRenderer: () => null,
  };

  const handleTableChange = (type, result) => {
    if (type === 'pagination') {
      setSizePerPage(result.sizePerPage);
      setPage(result.page);
      return;
    }

    if (type === 'filter') {
      const newFilter = [];
      for (const [name, valObj] of Object.entries(result.filters)) {
        if (name === 'ticketCategory.name') {
          newFilter.push({ ticketCategoryId: valObj.filterVal });
          continue;
        }
        if (name === 'status') {
          newFilter.push({ status: valObj.filterVal });
          continue;
        }
        if (name === 'paid') {
          if (valObj.filterVal === 'true') {
            newFilter.push({
              $or: [
                { 'customAttributes.donation': { $has: valObj.filterVal === 'true' } },
                { 'customAttributes.donationOper': { $has: valObj.filterVal === 'true' } },
              ],
            });
          } else {
            newFilter.push({
              $and: [
                { 'customAttributes.donation': { $has: valObj.filterVal === 'true' } },
                { 'customAttributes.donationOper': { $has: valObj.filterVal === 'true' } },
              ],
            });
          }
          continue;
        }
        if (['true', 'false'].includes(valObj.filterVal)) {
          newFilter.push({ [name]: valObj.filterVal === 'true' });
          continue;
        }
        newFilter.push({ [name]: { $ct: valObj.filterVal?.trim() } });
      }
      setTableFilter(newFilter);
    }
  };

  return (
    <>
      <PageHeader>{t('headline')}</PageHeader>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Form inline>
          <FormGroup controlId="formInlineName">
            <ControlLabel>Suche</ControlLabel>{' '}
            <FormControl type="text" onChange={(event) => setSearchTerm(event.target.value)} value={searchTerm} />
          </FormGroup>
        </Form>
      </div>

      <AdvancedBootstapTable
        remote
        keyField="id"
        columns={columns}
        data={orders}
        striped
        bordered={false}
        pagination={paginationFactory({
          page,
          sizePerPage,
          totalSize: meta?.total || 0,
          sizePerPageList: [10, 25, 50, 100],
          showTotal: true,
          paginationTotalRenderer,
          sizePerPageRenderer,
        })}
        onTableChange={(type, result) => handleTableChange(type, result)}
        filter={filterFactory()}
        expandRow={expandRow}
        filterPersistKey={`orderTableFilter_${window.eventId}`}
      />
    </>
  );
}

export default OrderPage;
