import React, { useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import { t } from '../../../locale';
import { getCustomersGql, getTicketCategoriesGql } from '../../customers/gql';
import AdvancedBootstapTable from '../../../components/AdvancedBootstapTable';
import toastHelper from '../../../lib/toastHelper';
import { titlesByValue } from '../../../lib/titleOptions';

export default function AddTableAttendee({ show, onHide, onAdd, selectedAttendees: alreadySelectedAttendees = [] }) {
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [tableFilter, setTableFilter] = useState([]);
  const [selectedAttendees, setSelectedAttendees] = useState([]);

  const filterToSend = useMemo(() => {
    let filterToSend = { $and: [] };

    if (tableFilter && tableFilter.length) {
      filterToSend = { $and: [...tableFilter, ...filterToSend.$and] };
    }
    if (!filterToSend.$and.length) return '';
    return encodeURIComponent(JSON.stringify(filterToSend));
  }, [tableFilter]);

  const { data: { getCustomers: { participants, meta } = { participants: [], meta: {} } } = {} } = useQuery(getCustomersGql, {
    skip: !show,
    fetchPolicy: 'no-cache',
    variables: {
      eventId: window.eventId,
      limit: sizePerPage,
      offset: (page - 1) * sizePerPage,
      searchTerm: '',
      filter: filterToSend,
      sort: '',
    },
    onError: (err) => {
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
  });

  const { data: categoriesResult } = useQuery(getTicketCategoriesGql, {
    skip: !show,
    variables: { eventId: window.eventId },
  });

  const categoryFilterOptions = useMemo(() => {
    if (!categoriesResult?.getTicketCategories?.ticketCategories) return [];
    return categoriesResult?.getTicketCategories?.ticketCategories?.map((cat) => ({ value: cat.id, label: cat.name }), {});
  }, [categoriesResult?.getTicketCategories?.ticketCategories]);

  const handleTableChange = (type, result) => {
    if (type === 'pagination') {
      setSizePerPage(result.sizePerPage);
      setPage(result.page);
      return;
    }

    if (type === 'filter') {
      const newFilter = [];
      for (const [name, valObj] of Object.entries(result.filters)) {
        if (name === 'ticketCategory.name') {
          newFilter.push({ ticketCategoryId: valObj.filterVal });
          continue;
        }
        if (name === 'status') {
          newFilter.push({ status: valObj.filterVal });
          continue;
        }
        if (['true', 'false'].includes(valObj.filterVal)) {
          newFilter.push({ [name]: valObj.filterVal === 'true' });
          continue;
        }
        newFilter.push({ [name]: { $ct: valObj.filterVal?.trim() } });
      }
      setTableFilter(newFilter);
    }
  };

  const handleSubmit = () => {
    if (onAdd && selectedAttendees.length) onAdd(selectedAttendees);
    setSelectedAttendees([]);
  };

  const columns = [
    {
      text: t('customersPayment.table.fullName'),
      dataField: 'fullName',
      filter: textFilter({ placeholder: t('customersPayment.table.filterPlaceholder.fullName') }),
      formatter: (cellContent, row) => (
        `${titlesByValue[row.title] || ''} ${row.forename} ${row.name}`
      ),
    },
    {
      text: t('customers.table.ticketCategory'),
      dataField: 'ticketCategory.name',
      filter: selectFilter({
        options: [{ value: '', label: t('customers.table.filterPlaceholder.allCategories') }, ...categoryFilterOptions],
        placeholder: t('customers.table.filterPlaceholder.ticketCategory'),
        withoutEmptyOption: true,
        defaultValue: '',
      }),
    },
    {
      text: t('customers.table.table'),
      dataField: 'eventTable.name',
    },
  ];

  // eslint-disable-next-line arrow-body-style
  const currentAttendeesWithTables = useMemo(() => {
    return participants.filter(({ eventTable }) => eventTable).map(({ internalId }) => internalId);
  }, [participants]);
  // eslint-disable-next-line arrow-body-style
  const selectedAttendeeIds = useMemo(() => {
    return alreadySelectedAttendees.map(({ internalId }) => internalId);
  }, [alreadySelectedAttendees]);

  const selectRow = {
    mode: 'checkbox',
    hideSelectAll: true,
    clickToSelect: true,
    nonSelectable: [...currentAttendeesWithTables, ...selectedAttendeeIds],
    nonSelectableClasses: (row) => {
      if (!row.eventTable) return '';
      if (selectedAttendeeIds.includes(row.internalId)) return 'info';
      return 'warning';
    },
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectedAttendees((selectedAttendees) => [...selectedAttendees, row]);
        return;
      }
      setSelectedAttendees((selectedAttendees) => [...selectedAttendees.filter(({ internalId }) => internalId !== row.internalId)]);
    },
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
        setSelectedAttendees([]);
      }}
      bsSize="large"
    >

      <Modal.Header closeButton>
        <Modal.Title>
          Teilnehmer zu Tisch hinzufügen
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <AdvancedBootstapTable
          remote
          keyField="internalId"
          columns={columns}
          data={participants}
          striped
          bordered={false}
          pagination={paginationFactory({ page, sizePerPage, totalSize: meta.total || 0, sizePerPageList: [10, 25, 50, 100] })}
          onTableChange={(type, result) => handleTableChange(type, result)}
          filter={filterFactory()}
          filterPersistKey={`tableCustomerFilter_${window.eventId}`}
          selectRow={selectRow}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button bsStyle="primary" onClick={handleSubmit}>
          {t('general.save')}
        </Button>
        <Button onClick={onHide}>{t('general.close')}</Button>
      </Modal.Footer>

    </Modal>
  );
}
