import React, { useEffect, useMemo, useState } from 'react';
import {
  Button, ButtonGroup, ButtonToolbar, ControlLabel, Form, FormControl, FormGroup, Glyphicon, PageHeader,
} from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { useDebounce, useSearchParam, useSessionStorage } from 'react-use';
import styled from 'styled-components';
import moment from 'moment';

import { useQuery, useMutation } from '@apollo/client';
import { t } from '../../../locale';
import { getAttributesGql, getCustomersGql, getTicketCategoriesGql, updateCustomerGql } from '../gql';
import toastHelper from '../../../lib/toastHelper';
import QueryBuilder from '../components/QueryBuilder';
import { titlesByValue } from '../../../lib/titleOptions';
import PaymentEditDialog from '../components/PaymentEditDialog';
import AdvancedBootstapTable, { paginationTotalRenderer, sizePerPageRenderer } from '../../../components/AdvancedBootstapTable';

function PaymentPage() {
  const [showPaymentEditModal, setShowPaymentEditModal] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState({});
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useSessionStorage(`customerPaymentSearch_${window.eventId}`, '');
  const [filter, setFilter] = useState({});
  const [tableFilter, setTableFilter] = useState([]);
  const filterQueryString = useSearchParam('filter');

  const { data: attributeResult } = useQuery(getAttributesGql, {
    variables: { eventId: window.eventId },
  });

  const { data: categoriesResult } = useQuery(getTicketCategoriesGql, {
    variables: { eventId: window.eventId },
  });

  const [updateCustomer, { loading: updateCustomerLoading }] = useMutation(updateCustomerGql);

  const payerCategories = useMemo(() => (
    categoriesResult?.getTicketCategories?.ticketCategories
      .filter(({ settings }) => settings?.needsPayment) || []
  ), [categoriesResult?.getTicketCategories?.ticketCategories]);

  const filterToSend = useMemo(() => {
    const payerCategoryIds = payerCategories.map(({ id }) => id);
    let filterToSend = { $and: [] };
    if (Object.keys(filter).length) filterToSend.$and.push(filter);
    if (payerCategoryIds && payerCategoryIds.length) filterToSend.$and.push({ ticketCategoryId: { $in: payerCategoryIds } });

    if (tableFilter && tableFilter.length) {
      filterToSend = { $and: [...tableFilter, ...filterToSend.$and] };
    }
    if (!filterToSend.$and.length) return '';
    return encodeURIComponent(JSON.stringify(filterToSend));
  }, [filter, tableFilter, payerCategories]);

  const { data: { getCustomers: { participants, meta } = { participants: [], meta: {} } } = {}, refetch } = useQuery(getCustomersGql, {
    fetchPolicy: 'no-cache',
    variables: {
      eventId: window.eventId,
      limit: sizePerPage,
      offset: (page - 1) * sizePerPage,
      searchTerm: encodeURIComponent(debouncedSearchTerm?.trim()),
      filter: filterToSend,
      sort: '',
    },
    onError: (err) => {
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
  });

  const attributeColumns = useMemo(() => {
    if (!attributeResult?.getAttributes?.attributes) return [];
    const euroFormatter = new Intl.NumberFormat('de-de', { style: 'currency', currency: 'EUR' });
    return attributeResult?.getAttributes?.attributes
      .filter(({ section }) => section === 'Spende / Beitrag')
      .map((attr) => {
        const overwrites = {};
        if (attr.type === 'boolean') {
          overwrites.formatter = (cell) => (cell !== undefined && cell !== null ? t(`general.form.boolean.${cell}`) : '');
        }
        if (attr.type === 'date') {
          overwrites.formatter = (cell) => (cell !== undefined && cell !== null ? moment(cell).format('L') : '');
        }
        if (attr.type === 'money') {
          overwrites.formatter = (cell) => (cell !== undefined && cell !== null ? euroFormatter.format(cell) : '');
        }
        return {
          text: attr.name,
          dataField: `customAttributes.${attr.code}`,
          ...overwrites,
        };
      });
  }, [attributeResult?.getAttributes?.attributes]);

  useDebounce(
    () => { setDebouncedSearchTerm(searchTerm); setPage(1); },
    400,
    [searchTerm],
  );

  useEffect(() => {
    if (filterQueryString) {
      setSearchTerm('');
      return;
    }
    setSearchTerm(debouncedSearchTerm);
  }, []);

  const categoryFilterOptions = useMemo(() => {
    if (!payerCategories) return [];
    return payerCategories.map((cat) => ({ value: cat.id, label: cat.name }), {});
  }, [payerCategories]);

  const columns = [
    {
      text: t('customersPayment.table.fullName'),
      dataField: 'fullName',
      filter: textFilter({ placeholder: t('customersPayment.table.filterPlaceholder.fullName') }),
      formatter: (cellContent, row) => (
        `${titlesByValue[row.title] || ''} ${row.forename} ${row.name}`
      ),
    },

    {
      text: t('customers.table.company'),
      dataField: 'company',
      filter: textFilter({ placeholder: t('customersPayment.table.filterPlaceholder.company') }),
    },
    /* { text: t('customers.table.email'), dataField: 'email', filter: textFilter({ placeholder: t('customers.table.filterPlaceholder.email') }) }, */
    {
      text: t('customers.table.ticketCategory'),
      dataField: 'ticketCategory.name',
      filter: selectFilter({
        options: [{ value: '', label: t('customers.table.filterPlaceholder.allCategories') }, ...categoryFilterOptions],
        placeholder: t('customers.table.filterPlaceholder.ticketCategory'),
        withoutEmptyOption: true,
        defaultValue: '',
      }),
    },
    {
      dataField: 'paid',
      text: 'Status',
      headerStyle: { textAlign: 'center' },
      isDummyField: true,
      filter: selectFilter({
        options: [
          { value: '', label: t('customersPayment.table.filterPlaceholder.all') },
          { value: true, label: t('customersPayment.table.paid') },
          { value: false, label: t('customersPayment.table.notPaid') },
        ],
        withoutEmptyOption: true,
        defaultValue: '',
      }),
      formatter: (cellContent, row) => {
        if (row.customAttributes?.donation || row.customAttributes?.donationOper) {
          return (
            <div style={{ textAlign: 'center' }}>
              <StatusIcon glyph="ok" className="text-success" />
              <br />
              <span className="text-success">{t('customersPayment.table.paid')}</span>
            </div>
          );
        }
        return (
          <div style={{ textAlign: 'center' }}>
            <StatusIcon glyph="remove" className="text-danger" />
            <br />
            <span className="text-danger">{t('customersPayment.table.notPaid')}</span>
          </div>
        );
      },
    },
    ...attributeColumns,
    {
      text: t('customers.table.linkedCustomer'),
      dataField: 'linkedCustomer',
      formatter: (cellContent, row) => (
        (row.linkedCustomer)
          ? (
            <a href={`/customers/edit/${window.eventId}/${row.linkedCustomer.id}`}>
              {`${row.linkedCustomer?.forename} ${row.linkedCustomer?.name}`}
            </a>
          ) : ''
      ),
    },
  ];

  if (window.eventRole !== 'viewer') {
    columns.push(
      {
        text: 'Aktion',
        isDummyField: true,
        headerStyle: () => ({ width: '70px' }),
        formatter: (cellContent, row) => (
          <ButtonGroup>
            <Button
              bsStyle="info"
              title={t('customers.showTicket')}
              onClick={() => {
                setSelectedParticipant(row);
                setShowPaymentEditModal(true);
              }}
            >
              <Glyphicon glyph="euro" />
            </Button>
          </ButtonGroup>
        ),
      },
    );
  }

  const handleTableChange = (type, result) => {
    if (type === 'pagination') {
      setSizePerPage(result.sizePerPage);
      setPage(result.page);
      return;
    }

    if (type === 'filter') {
      const newFilter = [];
      for (const [name, valObj] of Object.entries(result.filters)) {
        if (name === 'ticketCategory.name') {
          newFilter.push({ ticketCategoryId: valObj.filterVal });
          continue;
        }
        if (name === 'status') {
          newFilter.push({ status: valObj.filterVal });
          continue;
        }
        if (name === 'paid') {
          if (valObj.filterVal === 'true') {
            newFilter.push({
              $or: [
                { 'customAttributes.donation': { $has: valObj.filterVal === 'true' } },
                { 'customAttributes.donationOper': { $has: valObj.filterVal === 'true' } },
              ],
            });
          } else {
            newFilter.push({
              $and: [
                { 'customAttributes.donation': { $has: valObj.filterVal === 'true' } },
                { 'customAttributes.donationOper': { $has: valObj.filterVal === 'true' } },
              ],
            });
          }
          continue;
        }
        if (['true', 'false'].includes(valObj.filterVal)) {
          newFilter.push({ [name]: valObj.filterVal === 'true' });
          continue;
        }
        newFilter.push({ [name]: { $ct: valObj.filterVal?.trim() } });
      }
      setTableFilter(newFilter);
    }
  };

  return (
    <>
      <PageHeader>{t('customersPayment.headline')}</PageHeader>

      <ButtonToolbar style={{ marginBottom: 10 }}>
        <Button href={`/api/v1/events/${window.eventId}/participants/export?filter=${filterToSend}&query=${debouncedSearchTerm}`} target="_blank">
          {t('customers.buttons.export')}
        </Button>
      </ButtonToolbar>

      <QueryBuilder
        attributes={attributeResult?.getAttributes?.attributes}
        ticketCategories={categoriesResult?.getTicketCategories?.ticketCategories}
        onFilterApply={(newFilter) => { setFilter(newFilter); if (JSON.stringify(newFilter) === JSON.stringify(filter)) { refetch(); } }}
        filterPersistKey={`customerPaymentFilter_${window.eventId}`}
        useQueryParam={false}
      />

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Form inline>
          <FormGroup controlId="formInlineName">
            <ControlLabel>Suche</ControlLabel>{' '}
            <FormControl type="text" onChange={(event) => setSearchTerm(event.target.value)} value={searchTerm} />
          </FormGroup>
        </Form>
      </div>

      <AdvancedBootstapTable
        remote
        keyField="internalId"
        columns={columns}
        data={participants}
        striped
        bordered={false}
        pagination={paginationFactory({
          page,
          sizePerPage,
          totalSize: meta.total || 0,
          sizePerPageList: [10, 25, 50, 100],
          showTotal: true,
          paginationTotalRenderer,
          sizePerPageRenderer,
        })}
        onTableChange={(type, result) => handleTableChange(type, result)}
        filter={filterFactory()}
        filterPersistKey={`paymentTableFilter_${window.eventId}`}
      />
      <PaymentEditDialog
        show={showPaymentEditModal}
        onHide={() => {
          setShowPaymentEditModal(false);
          setSelectedParticipant(null);
        }}
        modalData={selectedParticipant}
        loading={updateCustomerLoading}
        onSubmit={async (data) => {
          await updateCustomer({
            variables: {
              eventId: window.eventId,
              customerId: data.id,
              input: { customAttributes: data.customAttributes },
            },
          });
          refetch();
          setShowPaymentEditModal(false);
          setSelectedParticipant(null);
        }}
      />
    </>
  );
}

const StatusIcon = styled(Glyphicon)`
    font-size: 20px;
    margin-right: 5px;
`;

export default PaymentPage;
