import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button, ButtonGroup, ButtonToolbar, ControlLabel, DropdownButton, Form, FormControl, FormGroup, Glyphicon, MenuItem, PageHeader,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faCaretRight, faCaretDown, faChild } from '@fortawesome/free-solid-svg-icons';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { useDebounce, useLocalStorage, useSearchParam, useSessionStorage } from 'react-use';
import styled from 'styled-components';
import Switch from 'react-bootstrap-switch';
import moment from 'moment';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { t } from '../../locale';
import { addPresenceGql, customerOverviewPageMetaGql, deleteCustomerGql, getCustomersGql, sendTicketGql } from './gql';
import ConfirmModal from '../../components/ConfirmModal';
import toastHelper from '../../lib/toastHelper';
import ImportModals from './components/ImportModals';
import QueryBuilder from './components/QueryBuilder';
import GuestTable from './components/GuestTable';
import AdvancedBootstapTable, { paginationTotalRenderer, sizePerPageRenderer } from '../../components/AdvancedBootstapTable';
import PageElement from './PageElement';

function CustomersPage() {
  const [showSendTicketModal, setShowSendTicketModal] = useState(null);
  const [page, setPage] = useSessionStorage(`customerPage_${window.eventId}`, 1);
  const [sizePerPage, setSizePerPage] = useState(25);
  const [confirmData, setConfirmData] = useState({});
  const [showOnlyHosts, setShowOnlyHosts] = useLocalStorage(`showOnlyHosts_${window.eventId}`, true);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useSessionStorage(`customerSearch_${window.eventId}`, '');
  const [filter, setFilter] = useState({});
  const [tableFilter, setTableFilter] = useState([]);
  const [sendTicketCustomerId, setSendTicketCustomerId] = useState(null);
  const importModalsRef = useRef();
  const customerEditModalRef = useRef();
  const filterQueryString = useSearchParam('filter');
  const [participants, setParticipants] = useState([]);
  const [meta, setMeta] = useState({});

  const { data: metaResult, loading: metaResultLoading } = useQuery(customerOverviewPageMetaGql, {
    variables: { organizerId: window.eventOrganizerId, eventId: window.eventId, settingKeys: ['attendeeDefaultPageLimit'].join(',') },
  });

  useEffect(() => {
    if (!metaResult?.getSettings?.attendeeDefaultPageLimit) return;
    if (sizePerPage !== 25) return;
    setSizePerPage(parseInt(metaResult?.getSettings?.attendeeDefaultPageLimit));
  }, [metaResult?.getSettings?.attendeeDefaultPageLimit]);

  const hostCategories = useMemo(() => (
    metaResult?.getTicketCategories?.ticketCategories
      .filter(({ settings }) => settings?.isHost) || []
  ), [metaResult?.getTicketCategories?.ticketCategories]);

  const subevents = useMemo(() => metaResult?.getSubevents?.subevents || [], [metaResult?.getSubevents?.subevents]);

  const filterToSend = useMemo(() => {
    const filterToSend = { $and: [] };
    if (Object.keys(filter).length) filterToSend.$and.push(filter);
    if (tableFilter && tableFilter.length) {
      filterToSend.$and.push(...tableFilter);
    }
    if (showOnlyHosts && hostCategories.length && window.eventFeatures.includes('attendeeMgmtV2')) {
      filterToSend.$and.push({ ticketCategoryId: { $in: hostCategories.map(({ id }) => id) } });
    }
    if (!filterToSend.$and.length) return '';
    return encodeURIComponent(JSON.stringify(filterToSend));
  }, [filter, tableFilter, showOnlyHosts, hostCategories]);

  const { refetch, loading } = useQuery(getCustomersGql, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      eventId: window.eventId,
      limit: sizePerPage,
      offset: (page - 1) * sizePerPage,
      searchTerm: encodeURIComponent(debouncedSearchTerm?.trim()),
      filter: filterToSend,
      sort: '',
      fields: 'invites',
    },
    onCompleted: ({ getCustomers: { meta, participants } }) => {
      setMeta(meta);
      setParticipants(participants);
    },
    onError: (err) => {
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
  });

  const [getCustomers] = useLazyQuery(getCustomersGql, {
    fetchPolicy: 'no-cache',
    variables: {
      eventId: window.eventId,
    },
    onError: (err) => {
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
  });
  const [sendTicket, { loading: sendTicketLoading }] = useMutation(sendTicketGql);
  const [deleteCustomer, { loading: deleteCustomerLoading }] = useMutation(deleteCustomerGql, {
    onError: (err) => {
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
    onCompleted: () => { refetch(); toastHelper.success(t('general.successMessages.deleted')); },
  });
  const [addPresence, { loading: addPresenceLoading }] = useMutation(addPresenceGql, {
    onError: (err) => {
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
    onCompleted: () => { refetch(); toastHelper.success(t('customers.checkinDialog.success')); },
  });

  const handleDelete = (customer, cb) => {
    setConfirmData({
      show: true,
      title: t('customers.deleteCustomer.title'),
      text: t('customers.deleteCustomer.text', { customer }),
      loading: deleteCustomerLoading,
      confirmBsStyle: 'danger',
      confirmMessage: t('invites.deleteInvite.delete'),
      onClose: () => { setConfirmData({}); },
      onConfirm: async () => {
        await deleteCustomer({ variables: { eventId: window.eventId, customerId: customer.id } });
        setConfirmData({});
        if (cb) cb();
      },
    });
  };

  const handleCheckin = (customer) => {
    const isCheckedIn = !!customer.generalInfo?.checkedIn;
    setConfirmData({
      show: true,
      title: t('customers.checkinDialog.title'),
      text: isCheckedIn
        ? t('customers.checkinDialog.textAlreadyExists', { customer, date: moment(customer.generalInfo?.generalInfo).format('DD.MM LT') })
        : t('customers.checkinDialog.text', { customer }),
      loading: addPresenceLoading,
      confirmBsStyle: 'primary',
      confirmMessage: t('customers.checkinDialog.checkin'),
      onClose: () => { setConfirmData({}); },
      hideConfirmButton: isCheckedIn,
      onConfirm: async () => {
        if (isCheckedIn) {
          setConfirmData({});
          return;
        }
        await addPresence({
          variables: {
            eventId: window.eventId,
            customerId: customer.id,
            input: {
              subeventId: metaResult.getSubevents.subevents[0].id,
              date: new Date().toISOString(),
            },
          },
        });
        refetch();
        setConfirmData({});
      },
    });
  };

  const attributeColumns = useMemo(() => {
    if (!metaResult?.getAttributes?.attributes) return [];
    return metaResult?.getAttributes?.attributes
      .filter(({ isVisibleInTable }) => isVisibleInTable)
      .map((attr) => {
        const overwrites = {};
        if (attr.type === 'boolean') {
          overwrites.formatter = (cell) => (cell !== undefined ? t(`general.form.boolean.${cell}`) : '');
          overwrites.filter = selectFilter({
            options: [
              { value: '', label: t('general.form.all') },
              { value: 'true', label: t('general.form.boolean.true') },
              { value: 'false', label: t('general.form.boolean.false') },
            ],
            placeholder: t('customers.table.filterPlaceholder.customerField', { attr }),
            withoutEmptyOption: true,
            defaultValue: '',
          });
          overwrites.headerStyle = () => ({ width: '100px' });
        }
        return {
          text: attr.name,
          dataField: `customAttributes.${attr.code}`,
          filter: textFilter({ placeholder: t('customers.table.filterPlaceholder.customerField', { attr }) }),
          ...overwrites,
        };
      });
  }, [metaResult?.getAttributes?.attributes]);

  const participantByInternalId = useMemo(() => (
    participants.reduce((acc, row) => {
      acc[row.internalId] = row;
      return acc;
    }, {})
  ), [participants]);

  useDebounce(
    () => { setDebouncedSearchTerm(searchTerm); if (searchTerm !== debouncedSearchTerm) setPage(1); },
    200,
    [searchTerm],
  );

  useEffect(() => {
    if (filterQueryString) {
      setSearchTerm('');
      return;
    }
    setSearchTerm(debouncedSearchTerm);
  }, []);

  useEffect(() => {
    if (page === 1) return;
    const pageCount = Math.ceil(meta.total / sizePerPage);
    if (page > pageCount) setPage(1);
  }, [page, sizePerPage, meta.total]);

  const categoryFilterOptions = useMemo(() => {
    if (!metaResult?.getTicketCategories?.ticketCategories) return [];
    return metaResult?.getTicketCategories?.ticketCategories.map((cat) => ({ value: cat.id, label: cat.name }), {});
  }, [metaResult?.getTicketCategories?.ticketCategories]);

  const renderStatusCell = useCallback((cellContent, row) => {
    const isPayer = row.ticketCategory?.settings?.needsPayment || false;
    return (
      <>
        <StatusIcon glyph="envelope" className={row?.generalInfo?.emailSent ? 'text-success' : ''} />
        {isPayer ? (
          <StatusIcon
            glyph="euro"
            className={
              row.customAttributes?.donation || row.customAttributes?.donationOper || row.customAttributes?.beitrag
                ? 'text-success'
                : 'text-danger'
            }
          />
        ) : null}
        {subevents?.length === 1 && (
          <StatusIconFA
            icon={faChild}
            title={row.generalInfo?.checkedIn
              ? t('customers.checkinStatus.checkedIn', { date: moment(row.generalInfo?.generalInfo).format('DD.MM LT') })
              : t('customers.checkinStatus.notCheckedIn')}
            className={
              row.generalInfo?.checkedIn
                ? 'text-success'
                : 'text-danger'
            }
          />
        )}
      </>
    );
  }, [subevents]);

  const columns = [
    {
      text: t('customers.table.forename'),
      dataField: 'forename',
      filter: textFilter({ placeholder: t('customers.table.filterPlaceholder.forename') }),
    },
    { text: t('customers.table.name'), dataField: 'name', filter: textFilter({ placeholder: t('customers.table.filterPlaceholder.name') }) },
    {
      text: t('customers.table.company'),
      dataField: 'company',
      filter: textFilter({ placeholder: t('customers.table.filterPlaceholder.company') }),
    },
    { text: t('customers.table.email'), dataField: 'email', filter: textFilter({ placeholder: t('customers.table.filterPlaceholder.email') }) },
    /* { text: 'Rechnungsnummer', dataField: 'invoiceNumber' }, */
    {
      text: t('customers.table.ticketCategory'),
      dataField: 'ticketCategory.name',
      filter: selectFilter({
        options: [{ value: '', label: t('customers.table.filterPlaceholder.allCategories') }, ...categoryFilterOptions],
        placeholder: t('customers.table.filterPlaceholder.ticketCategory'),
        withoutEmptyOption: true,
        defaultValue: '',
      }),
    },
    window.eventFeatures.includes('invites')
      ? {
        text: t('customers.table.registerDate'),
        dataField: 'registerDate',
        formatter: (cellContent) => cellContent && moment(cellContent).format('lll'),
      } : null,
    ...attributeColumns,
    window.eventFeatures.includes('tableMgmt')
      ? {
        text: t('customers.table.table'),
        dataField: 'eventTable',
        formatter: (cellContent, row) => (
          (row.eventTable)
            ? (
              <a href={`/events/tableEdit/${window.eventId}/${row.eventTable.id}`}>
                {`${row.eventTable?.name} (${row.eventTable.number ? `Tisch ${row.eventTable.number} ` : ''}Platz ${row.eventTable.seat})`}
              </a>
            ) : ''
        ),
      } : null,
  ].filter((column) => column);

  if (!showOnlyHosts) {
    columns.push(
      {
        text: t('customers.table.linkedCustomer'),
        dataField: 'linkedCustomer',
        formatter: (cellContent, row) => (
          (row.linkedCustomer)
            ? (
              <a href={`/customers/edit/${window.eventId}/${row.linkedCustomer.id}`}>
                {`${row.linkedCustomer?.forename} ${row.linkedCustomer?.name}`}
              </a>
            ) : ''
        ),
      },
    );
  }

  columns.push(
    {
      dataField: 'id',
      text: 'Status',
      isDummyField: true,
      headerStyle: () => ({ width: '90px' }),
      formatter: renderStatusCell,
    },
    {
      text: 'Aktion',
      isDummyField: true,
      formatter: (cellContent, row) => {
        const mainInvite = row.invites?.find(({ type }) => type === 'main');
        return (
          <ButtonGroup>
            {window.eventFeatures.includes('attendeeMgmtV2') ? (
              <Button
                bsStyle="primary"
                title={t('general.edit')}
                href={`/customers/edit/${window.eventId}/${row.id}`}
              >
                <Glyphicon glyph="pencil" />
              </Button>
            ) : (
              <Button
                bsStyle="primary"
                title={t('general.edit')}
                onClick={() => customerEditModalRef.current.openEditDialog(row)}
              >
                <Glyphicon glyph="pencil" />
              </Button>
            )}
            {window.eventFeatures.includes('invites') && (
              <Button
                bsStyle="info"
                title={t('invites.showInviteForm')}
                href={mainInvite?.url}
                target="_blank"
                disabled={!mainInvite?.url}
              >
                <Glyphicon glyph="list-alt" />
              </Button>
            )}
            <Button
              bsStyle="info"
              title={t('customers.showTicket')}
              href={`/customers/outputTicket/${window.eventId}/${row.internalId}`}
              target="_blank"
            >
              <Glyphicon glyph="barcode" />
            </Button>
            {(window.eventRole !== 'viewer') && (
              <>
                <Button
                  bsStyle="info"
                  title={t('customers.sendEmail')}
                  onClick={() => {
                    setShowSendTicketModal(t('customers.sendTicketDialog.text', { customer: row }));
                    setSendTicketCustomerId(row.id);
                  }}
                >
                  <FontAwesomeIcon icon={faPaperPlane} />
                </Button>
                {metaResult?.getSubevents?.subevents?.length === 1 && (
                  <Button
                    bsStyle="info"
                    title={t('customers.checkin')}
                    onClick={() => handleCheckin(row)}
                  >
                    <FontAwesomeIcon icon={faChild} />
                  </Button>
                )}
                <Button
                  bsStyle="danger"
                  title={t('general.delete')}
                  onClick={() => handleDelete(row)}
                >
                  <Glyphicon glyph="remove" />
                </Button>
              </>
            )}
          </ButtonGroup>
        );
      },
    },
  );

  const handleTableChange = (type, result) => {
    if (type === 'pagination') {
      setSizePerPage(result.sizePerPage);
      setPage(result.page);
      return;
    }

    if (type === 'filter') {
      const newFilter = [];
      for (const [name, valObj] of Object.entries(result.filters)) {
        if (['true', 'false'].includes(valObj.filterVal)) {
          newFilter.push({ [name]: valObj.filterVal === 'true' });
          continue;
        }
        if (name === 'ticketCategory.name') {
          newFilter.push({ ticketCategoryId: valObj.filterVal });
          continue;
        }
        if (name === 'status') {
          newFilter.push({ status: valObj.filterVal });
          continue;
        }
        newFilter.push({ [name]: { $ct: valObj.filterVal?.trim() } });
      }
      setTableFilter(newFilter);
    }
  };

  const onSendTicketConfirm = async () => {
    await sendTicket({
      variables: { eventId: window.eventId, customerId: sendTicketCustomerId },
      onError: () => toastHelper.error(t('customers.errors.sendTicketGeneralError')),
      onCompleted: () => toastHelper.success(t('customers.sendTicketDialog.success')),
    });

    setSendTicketCustomerId(null);
    setShowSendTicketModal(null);
  };

  const onAddSingleCustomer = () => {
    customerEditModalRef.current.openEditDialog(null);
  };

  const expandRow = {
    renderer: (customer) => <GuestTable {...customer} getCustomers={getCustomers} onDelete={handleDelete} />,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnRenderer: ({ expanded, rowKey }) => {
      if (!hostCategories?.map(({ name }) => name).includes(participantByInternalId[rowKey]?.ticketCategory?.name)) return null;
      return (
        <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={expanded ? faCaretDown : faCaretRight} />
      );
    },
    expandHeaderColumnRenderer: () => null,
  };

  return (
    <>
      <PageHeader>{t('customers.headline')}</PageHeader>

      <ButtonToolbar style={{ marginBottom: 10 }}>
        {(window.eventRole !== 'viewer') && (
          <DropdownButton
            id="createCustomerDropdown"
            bsStyle="primary"
            title={t('customers.buttons.addCustomer')}
          >
            <MenuItem
              href={!window.eventFeatures.includes('attendeeListV2') ? `/customers/add/${window.eventId}` : undefined}
              onClick={window.eventFeatures.includes('attendeeListV2') ? onAddSingleCustomer : undefined}
            >
              {t('customers.buttons.addSingleCustomer')}
            </MenuItem>

            <MenuItem onClick={() => importModalsRef.current.openExcelImport()}>
              {t('customers.buttons.importExcel')}
            </MenuItem>
            <MenuItem onClick={() => importModalsRef.current.openDigitStoreImport()}>
              {t('customers.buttons.importDigistore')}
            </MenuItem>
            <MenuItem onClick={() => importModalsRef.current.openElopageImport()}>
              {t('customers.buttons.importElopage')}
            </MenuItem>

          </DropdownButton>
        )}
        <Button href={`/api/v1/events/${window.eventId}/participants/export?filter=${filterToSend}&query=${debouncedSearchTerm}`} target="_blank">
          {t('customers.buttons.export')}
        </Button>

        {/* <Button
          disabled={!invites.length}
          onClick={() => handleSendMails()}
        >
          {t('invites.mail.sendAllMails.button')}
        </Button> */}

      </ButtonToolbar>

      <QueryBuilder
        attributes={metaResult?.getAttributes?.attributes}
        ticketCategories={metaResult?.getTicketCategories?.ticketCategories}
        onFilterApply={(newFilter) => { setFilter(newFilter); if (JSON.stringify(newFilter) === JSON.stringify(filter)) { refetch(); } }}
      />

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Form inline>
          {window.eventFeatures.includes('attendeeMgmtV2') && (
            <FormGroup style={{ marginRight: 30 }}>
              <ControlLabel style={{ marginRight: 10 }}>Nur Gastgeber anzeigen</ControlLabel>
              <Switch
                onText={t('general.form.boolean.true')}
                offText={t('general.form.boolean.false')}
                bsSize="small"
                onChange={(el, state) => setShowOnlyHosts(state)}
                value={showOnlyHosts}
              />
            </FormGroup>
          )}
          <FormGroup controlId="formInlineName">
            <ControlLabel>Suche</ControlLabel>{' '}
            <FormControl type="text" onChange={(event) => setSearchTerm(event.target.value)} value={searchTerm} />
          </FormGroup>
        </Form>
      </div>
      <AdvancedBootstapTable
        remote
        keyField="internalId"
        loading={loading || metaResultLoading}
        columns={columns}
        data={participants}
        pagination={paginationFactory({
          page,
          sizePerPage,
          totalSize: meta.total || 0,
          sizePerPageList: [10, 25, 50, 100],
          paginationTotalRenderer,
          sizePerPageRenderer,
          showTotal: true,
        })}
        onTableChange={(type, result) => handleTableChange(type, result)}
        filter={filterFactory()}
        expandRow={expandRow}
        filterPersistKey={`customerTableFilter_${window.eventId}`}
      />
      <ConfirmModal
        show={!!showSendTicketModal}
        title={t('customers.sendTicketDialog.title')}
        text={showSendTicketModal}
        loading={sendTicketLoading}
        confirmMessage={t('customers.sendTicketDialog.send')}
        onConfirm={onSendTicketConfirm}
        onClose={() => { setShowSendTicketModal(null); setSendTicketCustomerId(null); }}
      />
      <ConfirmModal
        show={confirmData.show}
        title={confirmData.title}
        text={confirmData.text}
        loading={confirmData.loading}
        confirmBsStyle={confirmData.confirmBsStyle}
        confirmMessage={confirmData.confirmMessage}
        onConfirm={confirmData.onConfirm}
        onClose={confirmData.onClose}
        hideConfirmButton={confirmData.hideConfirmButton}
      />
      <ImportModals
        ref={importModalsRef}
        onImportDone={refetch}
      />
      <PageElement
        ref={customerEditModalRef}
        onUpdateDone={refetch}
        onCreationDone={(customer) => {
          setSearchTerm(`${customer.forename} ${customer.name}`);
        }}
      />
    </>
  );
}

const StatusIcon = styled(Glyphicon)`
    font-size: 20px;
    margin-right: 5px;
`;

const StatusIconFA = styled(FontAwesomeIcon)`
    font-size: 20px;
    margin-right: 5px;
`;

export default CustomersPage;
