import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';

import './index.css';
import client from './ApolloProvider';
import SubeventsPage from './screens/subevents';
import CustomersPage from './screens/customers';
import CurrentActiveRfidAttendees from './screens/currentActiveRfidAttendees';
import DashboardPage from './screens/dashboard';
import HostStatus from './screens/hostStatus';
import LocalEventSettings from './screens/localEventSettings';
import CreateAndCheckinButton from './components/CreateAndCheckinButton';
import InvitesPage from './screens/invites';
import UserPage from './screens/users';
// import StatisticPage from './screens/statistics';
import InviteFormsPage from './screens/invites/pages/InviteForms';
import InviteFormEditPage from './screens/invites/pages/InviteFormEdit';
import CustomerEditPage from './screens/customers/pages/CustomerEdit';
import TablesPage from './screens/tables';
import TableEditPage from './screens/tables/pages/TableEdit';
import PaymentPage from './screens/customers/pages/Payment';
import CustomerAuditPage from './screens/customers/pages/CustomerAudit';
import DuplicatesPage from './screens/customers/pages/Duplicates';
import WaitingListPage from './screens/customers/pages/WaitingList';
import SeatWishPage from './screens/customers/pages/SeatWish';
import QuotasPage from './screens/quotas';
import CustomerPageElement from './screens/customers/PageElement';
import DevicesPage from './screens/devices';
import GroupsPage from './screens/customers/pages/Groups';
import SubeventPresencePage from './screens/subevents/pages/Presence';
import AdvancedDashboardPage from './screens/dashboard/pages/AdvancedDashboard';
import EventmanagementPage from './screens/eventmanagement';
import PrinterHostsPage from './screens/printerHosts';
import OrderPage from './screens/customers/pages/Order';
import OrderDetailsPage from './screens/customers/pages/OrderDetails';

Sentry.init({
  dsn: 'https://e9c16d90fb69bb3e8d95bb9e089a373d@o4505790970265600.ingest.sentry.io/4506067993886720',
});

const pageComponents = {
  subevents: SubeventsPage,
  subeventPresence: SubeventPresencePage,

  customers: CustomersPage,
  customerAdd: CustomerEditPage,
  customerEdit: CustomerEditPage,
  customerAudit: CustomerAuditPage,
  customerPageElement: CustomerPageElement,

  currentActiveRfidAttendees: CurrentActiveRfidAttendees,
  dashboard: DashboardPage,
  advancedDashboard: AdvancedDashboardPage,
  hostStatus: HostStatus,
  localEventSettings: LocalEventSettings,
  createAndCheckinButton: CreateAndCheckinButton,

  devices: DevicesPage,

  invites: InvitesPage,

  inviteForms: InviteFormsPage,
  inviteFormEdit: InviteFormEditPage,

  tables: TablesPage,
  tableEdit: TableEditPage,
  quotas: QuotasPage,

  paymentOverview: PaymentPage,
  duplicates: DuplicatesPage,
  waitingList: WaitingListPage,
  seatWish: SeatWishPage,
  groups: GroupsPage,

  users: UserPage,
  eventmanagement: EventmanagementPage,
  printerHosts: PrinterHostsPage,

  orderOverview: OrderPage,
  orderDetails: OrderDetailsPage,

  // statistics: StatisticPage,
};

// eslint-disable-next-line func-names
window.renderReactPage = function (page, divId) {
  const Component = pageComponents[page];
  ReactDOM.render(
    <ApolloProvider client={client}>
      <Component />
    </ApolloProvider>,
    document.getElementById(divId),
  );
};
