import React, { useCallback, useMemo, useRef } from 'react';
import { Button, Col, Form, PageHeader, Row } from 'react-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import { useMutation, useQuery } from '@apollo/client';
import styled from 'styled-components';

import { t } from '../../../locale';
import { createCustomerGql, getAttributesGql, getSingleCustomer, getTicketCategoriesGql, updateCustomerGql } from '../gql';
import FormPanel from '../components/FormPanel';
import toastHelper from '../../../lib/toastHelper';
import TablePanel from '../components/TablePanel';
import EmailLogPanel from '../components/EmailLogPanel';
import ParticipantsCrossEvent from '../components/ParticipantsCrossEvent';
import { titleOptions } from '../../../components/FormField';

export default function CustomerEditPage() {
  const isNew = !window.customerId;
  const tablePanelRef = useRef();
  const readOnly = window.eventRole === 'viewer';
  const { data: customerResult, loading, refetch } = useQuery(getSingleCustomer, {
    fetchPolicy: 'no-cache',
    skip: isNew,
    variables: {
      eventId: window.eventId,
      id: window.customerId,
    },
  });

  const { data: attributeResult } = useQuery(getAttributesGql, {
    variables: { eventId: window.eventId },
  });
  const { data: categoriesResult } = useQuery(getTicketCategoriesGql, {
    variables: { eventId: window.eventId },
  });
  const [createCustomer] = useMutation(createCustomerGql, {
    onError: (err) => {
      console.error(err);
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
    onCompleted: () => toastHelper.success(t('general.successMessages.created')),
  });
  const [updateCustomer] = useMutation(updateCustomerGql, {
    onError: (err) => {
      console.error(err);
      toastHelper.error(t([`general.errors.error${err.networkError.statusCode}`, 'general.errors.generalError']));
    },
    onCompleted: () => toastHelper.success(t('general.successMessages.saved')),
  });

  const onSubmit = async (data) => {
    // eslint-disable-next-line no-param-reassign
    const dataToSubmit = { ...data };
    if (data.eventTable) dataToSubmit.eventTable = { ...data.eventTable, name: undefined };

    const attendeeFields = attributeResult?.getAttributes?.attributes.filter(({ type }) => type === 'attendee');
    for (const field of attendeeFields) {
      if (!dataToSubmit?.customAttributes?.[field.code] || typeof dataToSubmit?.customAttributes?.[field.code] !== 'object') continue;
      dataToSubmit.customAttributes[field.code] = dataToSubmit.customAttributes[field.code].id;
    }

    if (isNew) {
      const result = await createCustomer({
        variables: {
          eventId: window.eventId,
          input: {
            participant: {
              ...dataToSubmit,
              __typename: undefined,
              id: undefined,
              ticket_category_id: undefined,
            },
          },
        },
      });
      return window.location.replace(`/customers/edit/${window.eventId}/${result.data.createCustomer.barcode}`);
    }

    await updateCustomer({
      variables: {
        eventId: window.eventId,
        customerId: window.customerId,
        input: {
          ...dataToSubmit,
          __typename: undefined,
          devices: undefined,
          id: undefined,
          invites: undefined,
          ticket_category_id: undefined,
          presences: undefined,
          participantsCrossEvent: undefined,
        },
      },
    });
    tablePanelRef.current?.refetch();
    return refetch();
  };

  const customer = useMemo(() => {
    const result = customerResult?.getCustomer
      || { title: 'mr', ticketCategory: { id: categoriesResult?.getTicketCategories?.ticketCategories[0]?.id } };
    const defaultCustomAttributes = (attributeResult?.getAttributes?.attributes || [])
      .reduce((acc, val) => {
        acc[val.code] = val?.settings?.defaultValue;
        if (val?.settings?.defaultValue !== undefined) return acc;
        if (val.type === 'titleSelect') acc[val.code] = titleOptions[0].value;
        if (val.type === 'boolean') acc[val.code] = val?.settings?.defaultValue ? 'true' : 'false';
        return acc;
      }, {});
    result.customAttributes = { ...defaultCustomAttributes, ...(result.customAttributes || {}) };
    return result;
  }, [
    customerResult?.getCustomer,
    categoriesResult?.getTicketCategories?.ticketCategories[0]?.id,
    attributeResult?.getAttributes?.attributes,
  ]);

  const categoriesSelect = useMemo(
    () => categoriesResult?.getTicketCategories?.ticketCategories.map((cat) => ({ value: cat.id, label: cat.name })) || [],
    [categoriesResult?.getTicketCategories?.ticketCategories],
  );

  const coreDataFields = useMemo(() => ({
    title: { name: 'title', label: t('customers.table.title'), required: true, type: 'titleSelect' },
    forename: { name: 'forename', label: t('customers.table.forename'), required: true },
    name: { name: 'name', label: t('customers.table.name'), required: true },
    company: { name: 'company', label: t('customers.table.company') },
    email: { name: 'email', label: t('customers.table.email'), type: 'email' },
    'ticketCategory.id': {
      name: 'ticketCategory.id',
      label: t('customers.table.ticketCategory'),
      required: true,
      type: 'select',
      options: categoriesSelect,
    },
  }), [categoriesSelect]);

  const onSubmitClick = (handleSubmit) => {
    handleSubmit();
    const el = document.querySelector('.has-error');
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  const getFormPanels = useCallback((values) => {
    if (!attributeResult?.getAttributes?.attributes) return [];

    const mapped = attributeResult?.getAttributes?.attributes.reduce((acc, attr) => {
      if (attr.visibility.toLowerCase().includes('system')) return acc;
      if (attr.settings?.onlyVisibleForTicketCategories
        && !attr.settings?.onlyVisibleForTicketCategories.includes(parseInt(values.ticketCategory.id))
      ) return acc;

      if (!acc[attr.section]) acc[attr.section] = [];

      if (attr.type === 'coreField') {
        if (!coreDataFields[attr.code]) return acc;
        acc[attr.section].push({ ...coreDataFields[attr.code], disabled: readOnly, grid: attr.settings?.grid });
        return acc;
      }

      acc[attr.section].push({
        name: `customAttributes.${attr.code}`,
        label: attr.name,
        type: attr.type,
        options: attr.options,
        disabled: readOnly,
        grid: attr.settings?.grid,
      });
      return acc;
    }, {});

    return Object.entries(mapped).map(([title, fields]) => ({ title, fields }));
  }, [attributeResult?.getAttributes?.attributes, coreDataFields]);

  if (loading) return (<h1>Loading...</h1>);
  if (!isNew && !customer) return (<h1>Customer not found</h1>);

  return (
    <>
      <PageHeader>{isNew ? t('customers.edit.addCustomer') : `Teilnehmer ${customer.forename} ${customer.name}`}</PageHeader>
      <FinalForm
        initialValues={customer}
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        render={({ handleSubmit, values }) => (
          <Form horizontal onSubmit={handleSubmit}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
              <ActionButton onClick={() => window.history.back()}>{t('general.back')}</ActionButton>
              {!isNew && <ActionButton href={`/customers/audit/${window.eventId}/${customer.id}`}>{t('customers.buttons.audit')}</ActionButton>}
              {!readOnly && (
                <ActionButton bsStyle="primary" onClick={() => onSubmitClick(handleSubmit)}>
                  {t('general.save')}
                </ActionButton>
              )}
            </div>
            <Row>
              <Col xs={12}>
                {getFormPanels(values).map((formPanel) => (<FormPanel {...formPanel} />))}
                <TablePanel ref={tablePanelRef} disabled={readOnly} />
                {!isNew && <EmailLogPanel disabled={readOnly} ticketCategories={categoriesResult?.getTicketCategories?.ticketCategories} />}
                {!isNew && <ParticipantsCrossEvent customer={customer} />}
              </Col>
            </Row>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
              {!readOnly && (
                <ActionButton
                  bsStyle="primary"
                  onClick={() => onSubmitClick(handleSubmit)}
                >
                  {t('general.save')}
                </ActionButton>
              )}
            </div>
          </Form>
        )}
      />
    </>
  );
}

const ActionButton = styled(Button)`
  margin-left: 5px;
`;
